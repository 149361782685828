import React from "react";
import { FaArrowDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { selectedServicesNew } from "../../Slice/serviceSlice";

const Availablity = ({ slot, setSlot, Available = [], selectedService ,disable,setdisable}) => {
  const { time } = slot;
  const dispatch = useDispatch();
  console.log(Available, slot, "slotting");
  const { Provider } = useSelector((state) => state.auth);
  const { SelectedService = [] } = Provider?.provider_category_detail || {};

  // const generateTimeSlots = (startTimes = [], interval = 0.5) => {
  //   if (!Array.isArray(startTimes) || startTimes.length === 0) return [];
  //   const slots = [];
  //   let current = moment(startTimes[0], "HH:mm");

  //   for (let i = 0; i < startTimes.length; i++) {
  //     slots.push(current.format("HH:mm"));
  //     current.add(interval * 60, "minutes"); // Convert hours to minutes
  //   }

  //   return slots;
  // };

  const timeSlots = Available;

  const handleSlotSelection = (timeSlot) => {
    dispatch(
      selectedServicesNew({
        ...Provider,
        provider_category_detail: {
          ...Provider.provider_category_detail,
          SelectedService: SelectedService.map((selectService) =>
            selectService.id === selectedService
              ? {
                  ...selectService,
                  dateslot: slot.day,
                  timeSlot,
                }
              : selectService
          ),
        },
      })
    );
    setSlot({ ...slot, time: timeSlot }); // Update the selected slot
  };
  console.log(SelectedService, "SelectedService");
  return (
    <div className="border p-4 rounded">
      {timeSlots.length < 1 ? (
        <div className="d-flex flex-column justify-content-center align-items-center gap-3">
          <div className="col-1">
            <img
              className="rounded-circle w-100"
              src="/assests/images/notfound.jpg"
              alt="fully booked"
            />
          </div>
          <h5>All Slots Are Book In This Date</h5>
          <h6>Sorry 😌</h6>
        </div>
      ) : (
      ""
      )}
      {Available.length > 0 && (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h5>Available Times</h5>
            <FaArrowDown size={20} color="#000" />
          </div>
          <div className="list-group list-group-flush">
            {timeSlots.map((timeSlot) => (
              <div
                key={timeSlot}
                className={`list-group-item list-group-item-action`}
                onClick={() => handleSlotSelection(timeSlot)}
                style={{
                  cursor: "pointer",
                  background: time === timeSlot ? "lightgrey" : "white",
                }}
              >
                {timeSlot}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Availablity;
