/* eslint-disable jsx-a11y/anchor-is-valid */
const LatestPost=()=>{
    return(
        <div className="latest-posts">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Section Title Start */}
              <div className="section-title">
                <h3 className="wow fadeInUp">Blog &amp; News</h3>
                <h2 className="text-anime">Latest Tips &amp; News</h2>
              </div>
              {/* Section Title End */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              {/* Post Item Start */}
              <div className="post-item wow fadeInUp" data-wow-delay="0.5s">
                {/* Post Featured Image Start */}
                <div className="post-featured-image">
                  <a href="#">
                    <figure className="hover-anime">
                      <img src="assests/images/post-1.jpg" alt="" />
                    </figure>
                  </a>
                </div>
                {/* Post Featured Image End */}
                {/* Post Header Start */}
                <div className="post-header">
                  <h3>
                    <a href="#">
                      Unlocking the Secrets to Healthy, Luscious Locks.
                    </a>
                  </h3>
                  <div className="post-meta">
                    <ul>
                      <li>
                        <a href="#">December 20, 2023</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Post Header End */}
                {/* Post Read More Button Start */}
                <div className="post-readmore">
                  <a href="#">
                    <img src="assests/images/icon-readmore.svg" alt="" />
                  </a>
                </div>
                {/* Post Read More Button End */}
              </div>
              {/* Post Item End */}
            </div>
            <div className="col-lg-4">
              {/* Post Item Start */}
              <div className="post-item wow fadeInUp" data-wow-delay="0.75s">
                {/* Post Featured Image Start */}
                <div className="post-featured-image">
                  <a href="#">
                    <figure className="hover-anime">
                      <img src="assests/images/gym.jpeg" alt="" height={250}  width={400} style={{objectFit:"cover"}}/>
                    </figure>
                  </a>
                </div>
                {/* Post Featured Image End */}
                {/* Post Header Start */}
                <div className="post-header">
                  <h3>
                    <a href="#">Trend Alert: Fitness 2024</a>
                  </h3>
                  <div className="post-meta">
                    <ul>
                      <li>
                        <a href="#">December 20, 2023</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Post Header End */}
                {/* Post Read More Button Start */}
                <div className="post-readmore">
                  <a href="#">
                    <img src="assests/images/icon-readmore.svg" alt="" />
                  </a>
                </div>
                {/* Post Read More Button End */}
              </div>
              {/* Post Item End */}
            </div>
            <div className="col-lg-4">
              {/* Post Item Start */}
              <div className="post-item wow fadeInUp" data-wow-delay="1.0s">
                {/* Post Featured Image Start */}
                <div className="post-featured-image">
                  <a href="#">
                    <figure className="hover-anime">
                      <img src="assests/images/yoga.jpeg" alt="" height={250}  width={400} style={{objectFit:"cover"}}/>
                    </figure>
                  </a>
                </div>
                {/* Post Featured Image End */}
                {/* Post Header Start */}
                <div className="post-header">
                  <h3>
                    <a href="#">
                      Behind the Chair: A Day in the Life of Our Yoga Class
                    </a>
                  </h3>
                  <div className="post-meta">
                    <ul>
                      <li>
                        <a href="#">December 20, 2023</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Post Header End */}
                {/* Post Read More Button Start */}
                <div className="post-readmore">
                  <a href="#">
                    <img src="assests/images/icon-readmore.svg" alt="" />
                  </a>
                </div>
                {/* Post Read More Button End */}
              </div>
              {/* Post Item End */}
            </div>
          </div>
        </div>
      </div>
    )
}

export default LatestPost