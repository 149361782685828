// import { FaStar } from "react-icons/fa";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const AboutTimming = ({provider}) => {
  // const data = [1, 2, 3, 4, 5, 6];
  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 31.5497,
    lng: 74.3436,
  };

  console.log(provider,'timimg')
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-12 mt-5">
            <div className="mt-5">
              <h1 className="text-dark">About</h1>
              <div className="d-flex gap-1 flex-wrap">
                <p
                  className="text-dark font-weight-bold"
                  style={{ fontWeight: "600" }}
                >
                {provider?.[0]?.about}
                </p>
              </div>
            </div>
            <LoadScript>
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={13}
                center={defaultCenter}
              >
                {/* Marker at the default location */}
                <Marker position={defaultCenter} />
              </GoogleMap>
            </LoadScript>
            <div className="row gy-2 mt-4">
              <div className="col-md-6 col-12  ">
                <div className="row mt-3">
                  <h2 className="text-dark">Opening times</h2>
                  <div className="col-md-6 col-6 mt-2">
                    {provider && provider?.[0]?.provider_opening_times?.length > 0 && provider?.[0]?.provider_opening_times.map((d,idx)=>(

                    <div key={idx} className="d-flex align-items-center gap-1"> <div className="circel" style={{backgroundColor:'#78D240'}}></div><h6 className="mt-2">{d.day}</h6></div>
                    )) }
                    {/* <div className="d-flex align-items-center gap-1"> <div className="circel" style={{backgroundColor:'#78D240'}}></div><h6 className="mt-2">Tuesday</h6></div>
                    <div className="d-flex align-items-center gap-1"> <div className="circel" style={{backgroundColor:'#78D240'}}></div><h6 className="mt-2">Thursday</h6></div>
                    <div className="d-flex align-items-center gap-1"> <div className="circel" style={{backgroundColor:'#78D240'}}></div><h6 className="mt-2">Friday</h6></div>
                    <div className="d-flex align-items-center gap-1"> <div className="circel" style={{backgroundColor:'#78D240'}}></div><h6 className="mt-2">Saturday</h6></div> */}
                    
                  </div>

                  <div className="col-md-6 col-6 mt-3">
                  {provider &&  provider?.[0]?.provider_opening_times?.length > 0 && provider?.[0]?.provider_opening_times.map((d,idx)=>(

                    <h6  style={{ marginTop:idx!==0?'15px':''}}>{d.open} - {d.close}</h6>
                  ))}
               
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12 mt-4">
                <h4 className="text-dark">Additional information</h4>
                {provider?.[0]?.provider_additional_features?.map((e)=>{
                   return(
                   <h6>{e.name}</h6>
                   )
                }) }
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12"></div>
        </div>
      </div>
    </>
  );
};

export default AboutTimming;
