/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FaStar } from "react-icons/fa";
// import RainBackground from "../ThreeJs/RainBackground";

const ServiceHero = ({ provider }) => {
  console.log(provider, "prof");
  return (
    <>
      <>
        {/* Hero Section Start */}

        <div className="heroservice">
          <div className="container mt-5">
            {/* <div className="row align-items-end">
                <div className="col-lg-8">
                  <div className="hero-content">
                    <div className="section-title">
                      <h3 className="wow fadeInUp"></h3>
                      <h1 className="text-anime">
                      Book local beauty and wellness services<br />
                      </h1>
                    </div>
                   
                
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="hero-image wow fadeInUp" data-wow-delay="0.75s">
                    <figure className="hover-anime">
                      <img src="assests/images/hero-img.jpg" alt="" />
                    </figure>
                  </div>
                </div>
              </div> */}
            {/* <div className="fadeInUp mt-3">
              <div className="bg-light rounded-pill px-3 py-3 d-flex sm:flex-wrap flex-nowrap">
                <input
                  className="w-100 searchinput fw-bold"
                  placeholder="Any treatment or venue"
                />
               
                <input
                  className="w-100 searchinput fw-bold"
                  placeholder="Any date"
                />
                <input
                  className="w-100 searchinput fw-bold"
                  placeholder="Any time"
                />
                <a href="#" className="btn-defaultS dark-bg">
                  Search
                </a>
              </div>
            </div> */}
            <div className="mt-5">
              {/* <div className="d-flex flex-wrap gap-3 mt-5">
                <h5 className="text-light">Home</h5>
                <h5 className="text-light">• Hair Salons</h5>
                <h5 className="text-light"> • Muscat</h5>
                <h5 className="text-light">• Tribes Men's Spa and Salon</h5>
              </div> */}
              <div className="mt-3">
                <h1 className="text-light">{provider?.about}</h1>
              </div>
              <div className="d-flex gap-4">
                <div className="d-flex ">
                  <div className="d-flex gap flex-wrap">
                    <div className="d-flex flex-wrap">
                      <div className="d-flex gap-1">
                        <h4 className="text-light">4.8</h4>
                        <FaStar size={26} color="white" />
                        <FaStar size={26} color="white" />
                        <FaStar size={26} color="white" />
                        <FaStar size={26} color="white" />
                        <FaStar size={26} color="white" />
                      </div>

                      <div className="d-flex flex-wrap gap-4">
                        <h5 className="text-light">
                          {" "}
                          • Open until {provider?.open_untill_time}
                        </h5>
                        <h5 className="text-light">
                          {" "}
                          • {provider?.address} {provider?.city}
                        </h5>
                        <h5 className="text-primary"> • Get directions</h5>
                      </div>
                    </div>
                    {/* <div className="d-flex gap-3">
                      <img src="/assests/images/export.png" alt="export" />
                      <img src="/assests/images/heart.png" alt="export" />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-8">
                <div className="hero-content">
                  <div className="section-title">
                    {/* <figure className="hover-anime frontmain"> */}
                    {provider?.provider_images?.[0]?.image && (
                      <img
                        className="frontmain"
                        src={provider?.provider_images[0]?.image}
                        alt=""
                      />
                    )}
                    {/* </figure> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="hero-image wow d-flex flex-column gap-3 fadeInUp"
                  data-wow-delay="0.75s"
                >
                  {/* <figure className="hover-anime"> */}
                  {provider?.provider_images?.[1]?.image && (
                    <img
                      className="front"
                      src={provider?.provider_images?.[1]?.image}
                      alt=""
                    />
                  )}
                  {/* </figure> */}
                  {/* <figure className="hover-anime"> */}
                  {provider?.provider_images?.[2]?.image && (
                    <img
                      className="front"
                      src={provider?.provider_images?.[2]?.image}
                      alt=""
                    />
                  )}
                  {/* </figure> */}
                </div>
              </div>
            </div>
            {/* Hero Scroll Down Arrow Start */}
            {/* <div className="row">
              <div className="col-md-12 scrollsp">
                <a href="#aboutus" className="scroll-down">
                  <span />
                </a>
              </div>
            </div> */}
            {/* Hero Scroll Down Arrow End */}
          </div>
        </div>
      </>
    </>
  );
};

export default ServiceHero;
