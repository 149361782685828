/* eslint-disable jsx-a11y/anchor-is-valid */
const GiftCard=()=>{
    return(
        <div className="gift-cards">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Section Title Start */}
              <div className="section-title">
                <h3 className="wow fadeInUp">Gifts &amp; Cards</h3>
                <h2 className="text-anime">Special Gifts &amp; Cards</h2>
              </div>
              {/* Section Title End */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              {/* Gift Offer Box Start */}
              <div className="gift-box">
                <div className="gift-content wow fadeInUp" data-wow-delay="0.5s">
                  <h3>Hair Cut</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the.
                  </p>
                  <a href="#" className="btn-default">
                    Get Now
                  </a>
                </div>
                <div className="gift-image wow fadeInUp" data-wow-delay="0.75s">
                  <img src="assests/images/gift-img-1.png" alt="" />
                </div>
              </div>
              {/* Gift Offer Box End */}
            </div>
            <div className="col-lg-6">
              {/* Gift Offer Box Start */}
              <div className="gift-box left-shape">
                <div className="gift-content wow fadeInUp" data-wow-delay="0.5s">
                  <h3>Yoga</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the.
                  </p>
                  <a href="#" className="btn-default">
                    Get Now
                  </a>
                </div>
                <div className="gift-image wow fadeInUp" data-wow-delay="1s">
                  <img src="assests/images/gift-img-2.png" alt="" />
                </div>
              </div>
              {/* Gift Offer Box End */}
            </div>
          </div>
        </div>
      </div>
    )
}

export default GiftCard