import axios from "axios";
import toast from "react-hot-toast";

export const axiosInstance = axios.create({
  baseURL: "https://studioapi.dev-sh.xyz/api/",
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token =
      localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const Data = error?.response?.data;
    const errors = Data.errors;
    console.log(errors);
    if (errors) {
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          toast.error(errors[key]);
        }
      }
    } else {
      toast.error(Data.message);
    }
    // if (Data.message === "Unauthenticated.") {
    //   localStorage.clear();
    //   window.location.reload();
    //   window.location.href = "/login";
    // }
    // return Promise.reject(error);
  }
);
