import React from "react";
import AllServices from "../Component/Service/AllServices";
import Layout from "../Component/Layout/Layout";

const AllProviders = () => {
  return (
    <Layout>
      <AllServices />
    </Layout>
  );
};

export default AllProviders;
