import axios from "axios";
import * as Yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Validation schema for the initial email field
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

// Validation schema for the OTP, Password, and Confirm Password fields
const extendedValidationSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const Forget = () => {
  const [otpSent, setOtpSent] = useState(false); // State to manage OTP submission status
  const navigate = useNavigate();

  const handleSubmit = (values) => {
    if (!otpSent) {
      // Handle the email submission
      axios
        .post(`https://studioapi.dev-sh.xyz/api/forgot-password`, { email: values.email })
        .then((response) => {
          if (response) {
            toast.success("OTP sent to your email");
            setOtpSent(true); // Display the OTP and password fields
          }
        })
        .catch((error) => {
          const { message } = error.response?.data || {};
          toast.error(message || "Something went wrong. Please try again.");
        });
    } else {
      // Handle the OTP and password submission
      axios
        .post(`http://studioapi.dev-sh.xyz/api/reset-password`, values)
        .then((response) => {
          toast.success("Password reset successful!");
          navigate("/Login");
        })
        .catch((error) => {
          const { message } = error.response?.data || {};
          toast.error(message || "Invalid OTP or password reset failed.");
        });
    }
  };

  return (
    <div className="global-container vh-100">
      <div className="card login-form" style={{ borderRadius: "30px" }}>
        <div className="card-body p-6 px-4 py-4">
          <h3 className="card-title text-center text-white text-3xl mb-4">
            Forget Password
          </h3>
          <div className="card-text">
            <Formik
              initialValues={{ email: "", otp: "", password: "", password_confirmation: "" }}
              validationSchema={otpSent ? extendedValidationSchema : validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="form-group mb-4">
                    <label htmlFor="email" className="text-white text-lg">
                      Email address
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control form-control-lg"
                      id="email"
                      aria-describedby="emailHelp"
                      disabled={otpSent} // Disable email field after OTP is sent
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger text-sm mt-1"
                    />
                  </div>

                  {/* OTP, Password, and Confirm Password fields will appear after OTP is sent */}
                  {otpSent && (
                    <>
                      <div className="form-group mb-4">
                        <label htmlFor="otp" className="text-white text-lg">
                          OTP
                        </label>
                        <Field
                          type="text"
                          name="otp"
                          className="form-control form-control-lg"
                          id="otp"
                        />
                        <ErrorMessage
                          name="otp"
                          component="div"
                          className="text-danger text-sm mt-1"
                        />
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="password" className="text-white text-lg">
                          New Password
                        </label>
                        <Field
                          type="password"
                          name="password"
                          className="form-control form-control-lg"
                          id="password"
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger text-sm mt-1"
                        />
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="password_confirmation" className="text-white text-lg">
                          Confirm Password
                        </label>
                        <Field
                          type="password"
                          name="password_confirmation"
                          className="form-control form-control-lg"
                          id="password_confirmation"
                        />
                        <ErrorMessage
                          name="password_confirmation"
                          component="div"
                          className="text-danger text-sm mt-1"
                        />
                      </div>
                    </>
                  )}

                  <button
                    type="submit"
                    className="btn btn-lg btn-block text-center text-black mt-2"
                    style={{ background: "white", width: "100%" }}
                 
                  >
                    {otpSent ? "Reset Password" : "Submit"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Toaster /> {/* Include Toaster for toast notifications */}
    </div>
  );
};
