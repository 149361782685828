
// import Layout from "../../Component/Layout/Layout";

import { Forget } from "../../Component/Auth/Forget"

export const Forgetpage = () => {
  return (
    <div>
    {/* <Layout> */}
    <Forget />
    {/* </Layout> */}</div>
  )
}
