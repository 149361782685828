const WhyChoose=()=>{
    return(
        <div className="why-choose-us">
        <div className="container">
          <div className="row align-items-center flex-column-reverse flex-lg-row">
            <div className="col-lg-6">
              {/* Section Title Start */}
              <div className="section-title">
                <h3 className="wow fadeInUp">Why Choose us ?</h3>
                <h2 className="text-anime">Are you Ready to Make a Big Change?</h2>
              </div>
              {/* Section Title End */}
              {/* Whyus Content Start  */}
              <div className="whyus-content">
                {/* Whyus Item Start */}
                <div
                  className="whyus-feature-item wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div className="whyus-icon">
                    <img src="assests/images/whyus-1.svg" alt="" />
                  </div>
                  <div className="whyus-desc">
                    <h3>Certified Trainers</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                      do eiusmod tempor incididunt ut labore.
                    </p>
                  </div>
                </div>
                {/* Whyus Item End */}
                {/* Whyus Item Start */}
                <div
                  className="whyus-feature-item wow fadeInUp"
                  data-wow-delay="0.75s"
                >
                  <div className="whyus-icon">
                    <img src="assests/images/whyus-2.svg" alt="" />
                  </div>
                  <div className="whyus-desc">
                    <h3>100% Trsuted Services</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                      do eiusmod tempor incididunt ut labore.
                    </p>
                  </div>
                </div>
                {/* Whyus Item End */}
              </div>
              {/* Whyus Content End  */}
            </div>
            <div className="col-lg-6">
              {/* Video Start */}
              <div className="why-choose-us-video">
                <div className="video-image">
                  <figure className="hover-anime">
                    <img src="assests/images/video-image.jpg" alt="" />
                  </figure>
                </div>
                <div className="video-play-button">
                  <a
                    href="https://www.youtube.com/watch?v=2JNMGesMC2Y"
                    className="popup-video"
                  >
                    <img src="assests/images/play.svg" alt="" />
                  </a>
                </div>
              </div>
              {/* Video End */}
            </div>
          </div>
        </div>
      </div>
    )
}

export default WhyChoose