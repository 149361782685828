import { useLocation } from "react-router-dom";
import SelectProfessional from "./Service/SelectProfessional";
import { useState } from "react";
import SelectedServices from "./Service/selectedServices";

const Professionals = () => {
  const data = useLocation();
  const { state } = data;
  const [ids, setIds] = useState({ id: "any", detail: null });
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-7 col-12 gap-2">
          <SelectProfessional selectedId={state} setIds={setIds} ids={ids} />
        </div>
        <div className="col-md-4 col-12">
          <SelectedServices
            navigate={`/bookNow/${ids.id}`}
            ServiceId={ids.detail}
          />
        </div>
      </div>
    </div>
  );
};

export default Professionals;
