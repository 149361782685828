const PhotoGallery=()=>{
    return(
        <div className="photo-gallery">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          {/* Section Title Start */}
          <div className="section-title">
            <h3 className="wow fadeInUp">Photo Gallery</h3>
            <h2 className="text-anime">Inside Look at Our Services</h2>
          </div>
          {/* Section Title End */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="photo-gallery-ticker">
            {/* Photo Gallery assests/Images Start */}
            <div className="photo-gallery-content">
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/f1.jpeg" alt="" height={500} style={{objectFit:"cover"}}/>
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/f2.jpeg" alt="" height={500} style={{objectFit:"cover"}}/>
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/photo-3.jpg" alt="" />
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/photo-4.jpg" alt="" />
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/photo-5.jpg" alt="" />
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/f3.jpeg" alt="" height={500} style={{objectFit:"cover"}}/>
                </figure>
              </div>
            </div>
            <div className="photo-gallery-content">
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/photo-1.jpg" alt="" />
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/photo-2.jpg" alt="" />
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/photo-3.jpg" alt="" />
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/photo-4.jpg" alt="" />
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/photo-5.jpg" alt="" />
                </figure>
              </div>
              <div className="photo-gallery-item">
                <figure className="hover-anime">
                  <img src="assests/images/photo-6.jpg" alt="" />
                </figure>
              </div>
            </div>
            {/* Photo Gallery assests/Images End */}
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}

export default PhotoGallery