import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Fog } from "three";

const RainBackground = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    let camera, scene, renderer;
    let controls;
    let particles, rainGeo, rainMaterial;

    const init = () => {
      const { innerWidth, innerHeight } = window;

      // Camera
      camera = new THREE.PerspectiveCamera(
        60,
        innerWidth / innerHeight,
        0.1,
        110
      );
      camera.position.set(40, 8, 0);
      camera.lookAt(0, 0, 0);

      // Scene
      scene = new THREE.Scene();
      scene.fog = new Fog(0x333333, 1, 500); // Add fog for depth

      // Lights
      const ambientLight = new THREE.AmbientLight(0x404040); // Soft ambient light
      scene.add(ambientLight);

      const dirLight = new THREE.DirectionalLight(0xffffff, 0.5);
      dirLight.position.set(3, 17, 17);
      scene.add(dirLight);

      // Rain effect
      const rainCount = 34085;
      rainGeo = new THREE.BufferGeometry();
      const positions = [];
      const velocities = [];

      for (let i = 0; i < rainCount; i++) {
        positions.push(
          Math.random() * 400 - 200,
          Math.random() * 700 - 350 + 150, // Increased Y-axis range
          Math.random() * 400 - 200
        );
        velocities.push(0, -Math.random() * 0.4 - 0.2, 0); // Increased speed
      }

      rainGeo.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(positions, 3)
      );
      rainGeo.setAttribute(
        "velocity",
        new THREE.Float32BufferAttribute(velocities, 3)
      );

      rainMaterial = new THREE.PointsMaterial({
        color: 0xaaaaaa,
        size: 0.2, // Adjust size for more impact
        transparent: true,
        opacity: 0.8, // Adjust opacity
      });

      particles = new THREE.Points(rainGeo, rainMaterial);
      scene.add(particles);

      // Renderer
      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(innerWidth, innerHeight * 2); // Render for double height
      mountRef.current.appendChild(renderer.domElement);

      // Controls
      controls = new OrbitControls(camera, renderer.domElement);
      controls.minDistance = 5;
      controls.maxDistance = 50;
      controls.update();

      // Render loop
      const animate = () => {
        const positions = rainGeo.attributes.position.array;
        const velocities = rainGeo.attributes.velocity.array;

        for (let i = 0; i < rainCount * 3; i += 3) {
          positions[i + 1] += velocities[i + 1];

          if (positions[i + 1] < -350) { // Adjust lower bound for falling rain
            positions[i + 1] = 350; // Adjust respawn height
          }
        }

        rainGeo.attributes.position.needsUpdate = true;
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      };
      animate();

      // Handle window resize
      window.addEventListener("resize", onWindowResize);
    };

    const onWindowResize = () => {
      const { innerWidth, innerHeight } = window;
      camera.aspect = innerWidth / (innerHeight * 2); // Double the aspect ratio for height
      camera.updateProjectionMatrix();
      renderer.setSize(innerWidth, innerHeight * 2); // Ensure renderer height is doubled
    };

    init();

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  return (
    <div
      ref={mountRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "130vh", // Set to 200% of viewport height
        overflow: "hidden",
        zIndex:"-100"
      }}
    />
  );
};

export default RainBackground;
