import axios from "axios";
import React, { useEffect, useState } from "react";
import { Loader } from "../Loader/Loader";
import { Link } from "react-router-dom";
const AllServices = () => {
  const [providers, setProviders] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const fetchAllProviders = async () => {
      setLoader(true);
      await axios
        .get(`https://studioapi.dev-sh.xyz/api/provider`)
        .then((res) => {
          setProviders(res.data.data);
          setLoader(false);
        })
        .catch((err) => console.log(err));
    };
    fetchAllProviders();
  }, []);

  console.log(providers);

  if (loader) {
    return <Loader />;
  }
  return (
    <div className="latest-posts">
      <div className="container">
        <div className="fadeInUp mt-3">
          <div className="bg-light rounded-pill px-3 py-3 d-flex sm:flex-wrap flex-nowrap">
            <input
              className="w-100 searchinput fw-bold"
              placeholder="Any treatment or venue"
            />
            {/* <input
                  className="w-100 searchinput fw-bold"
                  placeholder="location"
                /> */}
            <input
              className="w-100 searchinput fw-bold"
              placeholder="Any date"
            />
            <input
              className="w-100 searchinput fw-bold"
              placeholder="Any time"
            />
            <button className="btn-defaultS dark-bg">Search</button>
          </div>
        </div>
        <div className="row" style={{ paddingTop: "40px" }}>
          <div className="col-md-12">
            {/* Section Title Start */}
            <div className="section-title">
              <h3 className="wow fadeInUp">Providers</h3>
              <h2 className="text-anime">All Providers</h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        <div className="row">
          {providers.map((p, idx) => (
            
            <div
              key={idx}
              className="col-lg-4"
              style={{ marginBottom: "15px" }}
            >
            <Link to={`/otherservice/${p.id}`} state={p}>
              {/* Post Item Start */}
              <div className="post-item wow fadeInUp" data-wow-delay="0.5s">
                {/* Post Featured Image Start */}
                <div className="post-featured-image">
                  <a href={`/services/${p.id}`}>
                    <figure className="hover-anime">
                      <img
                        src={p.image}
                        alt=""
                        height={300}
                        width={500}
                        style={{ objectFit: "cover" }}
                      />
                    </figure>
                  </a>
                </div>
                {/* Post Featured Image End */}
                {/* Post Header Start */}
                <div className="post-header">
                  <h3>
                    <a href={`/services/${p.id}`}>{p.first_name}</a>
                  </h3>
                  <div className="post-meta">
                    <ul>
                      <li>
                        <a href={`/services/${p.id}`}>{p.about}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Post Header End */}
                {/* Post Read More Button Start */}
                <div className="post-readmore">
                  <a href={`/services/${p.id}`}>
                    <img src="assests/images/icon-readmore.svg" alt="" />
                  </a>
                </div>
                {/* Post Read More Button End */}
              </div>
              {/* Post Item End */}
            </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllServices;
