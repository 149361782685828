import axios from "axios";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import RainBackground from "../ThreeJs/RainBackground";

const Regerister = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    phone: Yup.string().required("Phone number is required"),
    image: Yup.mixed().required("Image is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    console.log(values); // For debugging
    setLoader(true);
    const data = new FormData();

    data.append("first_name", values.first_name);
    data.append("last_name", values.last_name);
    data.append("email", values.email);
    data.append("password", values.password);
    data.append("image", values.image); // Check if this is correctly populated
    data.append("phone", values.phone);
    data.append("role", "user");

    axios
      .post(`https://studioapi.dev-sh.xyz/api/signup`, data)
      .then((response) => {
        navigate("/login");
        const { message, status } = response.data;
        if (status) {
          toast.success(message);
          resetForm();
        } else {
          toast.error(message);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.error(error?.response?.data?.message,'resmesage');
        toast.error(error?.response?.data?.message);
        setLoader(false);
      });
  };
  return (
    <>
      <RainBackground />
      <div className="global-container vh-100">
        <div className="card login-form" style={{ borderRadius: "30px" }}>
          <div className="card-body p-4 px-4">
            <h3 className="card-title text-center text-white text-3xl">
              Registration
            </h3>
            <div className="card-text">
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: "",
                  password: "",
                  image: null,
                  phone: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form>
                    <div className="form-group mb-1">
                      <label className="text-white text-lg">First Name</label>
                      <Field
                        name="first_name"
                        type="text"
                        className="form-control form-control-lg"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="form-group mb-1">
                      <label className="text-white text-lg">Last Name</label>
                      <Field
                        name="last_name"
                        type="text"
                        className="form-control form-control-lg"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="form-group mb-1">
                      <label className="text-white text-lg">
                        Email address
                      </label>
                      <Field
                        name="email"
                        type="email"
                        className="form-control form-control-lg"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="form-group mb-1">
                      <label className="text-white text-lg">Password</label>
                      <Field
                        name="password"
                        type="password"
                        className="form-control form-control-lg"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="form-group mb-1">
                      <label className="text-white text-lg">Image</label>
                      <input
                        name="image"
                        type="file"
                        className="form-control form-control-lg"
                        onChange={(event) =>
                          setFieldValue("image", event.currentTarget.files[0])
                        }
                      />
                      <ErrorMessage
                        name="image"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <div className="form-group mb-1">
                      <label className="text-white text-lg">Phone Number</label>
                      <Field
                        name="phone"
                        type="text"
                        className="form-control form-control-lg"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="text-danger text-sm"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-lg btn-block text-center text-black mt-2"
                      style={{ background: "white", width: "100%" }}
                      disabled={loader}
                    >
                      {loader ? "Submitting..." : "Submit"}
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Regerister;
