import React from "react";

export const Loader = () => {
  return (
    <>
      {" "}
      <div className="preloader">
        <div className="loading-container">
          <div className="loading" />
          <div id="loading-icon">
            <img src="assests/images/loader.svg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
