import { FaStar } from "react-icons/fa";

const Review = () => {
  const data   = [1, 2, 3, 4, 5, 6,7,8];

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="mt-5">
            <h2 className="text-dark">Reviews</h2>
            <div className="d-flex gap-1 flex-wrap">
              <FaStar color="black" size={30} />
              <FaStar color="black" size={30} />
              <FaStar color="black" size={30} />
              <FaStar color="black" size={30} />
            </div>
          </div>
          <div className="col-md-7 col-12 mt-5">
            <div className="row gy-2" style={{ marginTop: "-60px" }}>
              {data.map(() => {
                return (
                  <div className="col-md-6 col-12 mt-5">
                    <div className="d-flex gap-3 align-items-center">
                      <img
                        src="/assests/images/reviewicon.png"
                        height={"75px"}
                        alt="ss"
                      />
                      <div className="mt-3">
                        <h6 style={{ marginBottom: "-1px" }}>Kareem S.</h6>
                        <p>Today Sep 16, 2024 at 1:37 AM</p>
                      </div>
                    </div>
                    <div>
                      <div className="d-flex gap-1 flex-wrap">
                        <FaStar color="black" size={17} />
                        <FaStar color="black" size={17} />
                        <FaStar color="black" size={17} />
                        <FaStar color="black" size={17} />
                      </div>
                      <h6 className="text-dark mt-2">
                        It Was Nice! Have a Nice Day
                      </h6>
                    </div>
                  </div>
                );
              })}
            </div>
            <button className="btn btn-light border font-weight-bold mt-4">See all</button>
          </div>
          <div className="col-md-4 col-12"></div>
        </div>
      </div>
    </>
  );
};

export default Review;
