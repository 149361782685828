const PricingSection = () => {
  return (
    <div className="pricing">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* Section Title Start */}
            <div className="section-title">
              <h3 className="wow fadeInUp">Price List</h3>
              <h2 className="text-anime">Our Best Prices</h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6">
            {/* Category: Beauty */}
            <div className="pricing-item wow fadeInUp" data-wow-delay="0.50s">
              <div className="pricing-info">
                <h3>Haircut</h3>
                <p>Professional haircut tailored to your style.</p>
              </div>
              <div className="pricing-price">
                <p>$29</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            {/* Category: Beauty */}
            <div className="pricing-item wow fadeInUp" data-wow-delay="0.74s">
              <div className="pricing-info">
                <h3>Organic Facial</h3>
                <p>Refresh your skin with our organic facial treatment.</p>
              </div>
              <div className="pricing-price">
                <p>$49</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            {/* Category: Beauty */}
            <div className="pricing-item wow fadeInUp" data-wow-delay="1.0s">
              <div className="pricing-info">
                <h3>Eyebrow Shaping</h3>
                <p>Enhance your beauty with perfect eyebrow shaping.</p>
              </div>
              <div className="pricing-price">
                <p>$39</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            {/* Category: Health */}
            <div className="pricing-item wow fadeInUp" data-wow-delay="1.25s">
              <div className="pricing-info">
                <h3>Nutrition Consultation</h3>
                <p>Personalized nutrition plans to fit your lifestyle.</p>
              </div>
              <div className="pricing-price">
                <p>$59</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            {/* Category: Health */}
            <div className="pricing-item wow fadeInUp" data-wow-delay="1.50s">
              <div className="pricing-info">
                <h3>Yoga Class</h3>
                <p>Join our rejuvenating yoga sessions.</p>
              </div>
              <div className="pricing-price">
                <p>$15</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            {/* Category: Health */}
            <div className="pricing-item wow fadeInUp" data-wow-delay="1.75s">
              <div className="pricing-info">
                <h3>Mental Health Counseling</h3>
                <p>Support for your mental wellness journey.</p>
              </div>
              <div className="pricing-price">
                <p>$80</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            {/* Category: Fitness */}
            <div className="pricing-item wow fadeInUp" data-wow-delay="2.00s">
              <div className="pricing-info">
                <h3>Personal Training Session</h3>
                <p>One-on-one training tailored to your goals.</p>
              </div>
              <div className="pricing-price">
                <p>$50</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            {/* Category: Fitness */}
            <div className="pricing-item wow fadeInUp" data-wow-delay="2.25s">
              <div className="pricing-info">
                <h3>Group Fitness Class</h3>
                <p>Join our fun group workouts!</p>
              </div>
              <div className="pricing-price">
                <p>$12</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            {/* Category: Fitness */}
            <div className="pricing-item wow fadeInUp" data-wow-delay="2.50s">
              <div className="pricing-info">
                <h3>Gym Membership</h3>
                <p>Access to all gym facilities and classes.</p>
              </div>
              <div className="pricing-price">
                <p>$39</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingSection;
