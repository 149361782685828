/* eslint-disable no-unused-vars */
import { useState } from "react";
import Marquee from "react-fast-marquee";
import { axiosInstance } from "../../utils/axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { selectedServicesNew } from "../../Slice/serviceSlice";
import { useNavigate } from "react-router-dom";

/* eslint-disable jsx-a11y/anchor-is-valid */
const HeroSection = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { Services, Provider } = useSelector((state) => state.auth);
  console.log(Provider, "provider");

  const { id } = Provider.provider_category_detail;
  console.log(id, "id");
  const wellnessServices = [
    { id: 1, name: "Gym", icon: "images/ticker-icon.svg" },
    { id: 2, name: "Nutrition Counseling", icon: "images/ticker-icon.svg" },
    { id: 3, name: "Yoga Therapy", icon: "images/ticker-icon.svg" },
    { id: 4, name: "Healthy Eating Workshops", icon: "images/ticker-icon.svg" },
    { id: 5, name: "Meditation Classes", icon: "images/ticker-icon.svg" },
    { id: 6, name: "Massage Therapy", icon: "images/ticker-icon.svg" },
    { id: 7, name: "Aromatherapy", icon: "images/ticker-icon.svg" },
    { id: 8, name: "Personal Training", icon: "images/ticker-icon.svg" },
    { id: 9, name: "Mindfulness Sessions", icon: "images/ticker-icon.svg" },
    { id: 10, name: "Skincare Treatments", icon: "images/ticker-icon.svg" },
    { id: 11, name: "Fitness Challenges", icon: "images/ticker-icon.svg" },
    { id: 12, name: "Shaving", icon: "images/ticker-icon.svg" },
    { id: 13, name: "Detox Diets", icon: "images/ticker-icon.svg" },
    {
      id: 14,
      name: "Stress Management Workshops",
      icon: "images/ticker-icon.svg",
    },
    { id: 15, name: "Organic Facial", icon: "images/ticker-icon.svg" },
  ];

  // const [searchService, setSearchService] = useState([]);
  // const [serviceName, setServiceName] = useState("");
  // const navigate = useNavigate();

  // const handleServiceClick = (service) => {
  //   try {
  //     console.log(service, "Selected Service");
  //     const { provider_category_detail } = service;
  //     dispatch(selectedServicesNew(service)); // Redux action call
  //     navigate(`services/${provider_category_detail?.id}`);
  //   } catch (error) {
  //     console.error("Error handling service selection", error);
  //   }
  // };

  // const fetchSuggestions = async (name) => {
  //   if (!name) {
  //     setSearchService([]);
  //     return;
  //   }
  //   try {
  //     const response = await axiosInstance.get(`search-service/${name}`);
  //     if (response?.data?.data?.length > 0) {
  //       setSearchService(response.data.data);
  //     } else {
  //       setSearchService([]);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching suggestions", error);
  //   }
  // };
  return (
    <>
      {/* Hero Section Start */}

      <div className="hero z-1" style={{ backgroundColor: "transparent" }}>
        <div className="container position-relative z-1">
          <div className="row align-items-center">
            <div className="col-lg-8">
              {/* Hero Left Content Start */}
              <div className="hero-content">
                <div className="section-title">
                  <h3 className="wow fadeInUp">Welcome to Studio</h3>
                  <h1 className="text-anime">
                    Book local beauty and wellness services
                    <br />
                    {/* &amp; New Style */}
                  </h1>
                </div>
                {/* <div
                    className="hero-content-body wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <p>
                      We believe that your hair is an expression of your
                      personality. Our skilled stylists are here to help you
                      unleash your true style potential.
                    </p>
                    <ul>
                      <li>01. Get Hair Style You Deserve</li>
                      <li>02. Perfect Hair Style</li>
                    </ul>
                  </div> */}
                {/* <div
                    className="hero-content-footer wow fadeInUp"
                    data-wow-delay="0.75s"
                  >
                    <a href="#" className="btn-default dark-bg">
                      Book Now
                    </a>
                    <a href="#" className="btn-default dark-bg">
                      Contact Now
                    </a>
                  </div> */}
              </div>
              {/* Hero Left Content End */}
            </div>
            <div className="col-lg-4">
              {/* Hero Image Start */}
              <div className="hero-image wow fadeInUp" data-wow-delay="0.75s">
                <figure className="hover-anime">
                  <img src="assests/images/hero-img.jpg" alt="" />
                </figure>
              </div>
              {/* Hero Image End */}
            </div>
          </div>

          {/* <div className="fadeInUp mt-3">
            <div className="d-flex  justify-content-end">
              <div
                className="bg-light rounded-pill px-3 py-2 d-flex flex-column gap-2"
                style={{ width: "50%" }}
              >
                <input
                  value={serviceName}
                  onChange={(e) => {
                    setServiceName(e.target.value);
                    fetchSuggestions(e.target.value);
                  }}
                  className="searchinput fw-bold form-control"
                  placeholder="Service Name"
                />
              </div>
              {searchService.length > 0 && (
                <div
                  className="suggestions-list  mt-2"
                  style={{ width: "50%" }}
                >
                  {searchService.map((service, index) => (
                    <div
                      key={index}
                      className="suggestion-item"
                      onClick={() => handleServiceClick(service)}
                    >
                      {service?.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div> */}
        </div>
        {/* Features Ticker Section Start */}
        <div className="features-ticker mt-5 bg-transparent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {/* Features Ticker Start */}
                <div className="feature-ticker-box">
                  <Marquee gradient={false} speed={10}>
                    <ul
                      className="feature-ticker-content"
                      style={{ listStyle: "none", fontSize: "25px" }}
                    >
                      {wellnessServices.map((service) => (
                        <li className="text-white" key={service.id}>
                          {service.name}
                        </li>
                      ))}
                    </ul>
                  </Marquee>
                </div>
                {/* Features Ticker End */}
              </div>
            </div>
          </div>
        </div>
        {/* Features Ticker Section End */}
        {/* Hero Scroll Down Arrow Start */}
        <div className="row">
          <div className="col-md-12 scrollsp">
            <a href="#aboutus" className="scroll-down" style={{ bottom: "2%" }}>
              <span />
            </a>
          </div>
        </div>
        {/* Hero Scroll Down Arrow End */}
      </div>
      {/* Hero Section End */}
    </>
  );
};

export default HeroSection;
