import React from "react";


export default function Team() {
  const data = [1, 2, 3];
  return (
    <>
      <div className="container mt-5">
        <div className="row gap-2">
          <div className="col-md-7 col-12">
            <h1 className="text-dark">Team</h1>
            <div className="d-flex flex-wrap justify-content-md-start justify-content-center px-4 gap-5">
              {data.map(() => {
                return (
                  <>
                    <div className="d-flex flex-column">
                      <img
                        className="rounded-circle"
                        src="/assests/images/rewive.png"
                        alt="rewiw"
                      />
                      <div className="d-flex justify-content-center">
                        <button
                          className="rounded-pill border-none bg-light w-50"
                          style={{ marginTop: "-20px", border: "none" }}
                        >
                          4.5
                        </button>
                      </div>
                      <div className="d-flex  flex-column mt-1">
                        <h5 className="text-center text-dark">Randy</h5>
                        <p className="text-center">Spa Therapist</p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <div className="col-md-4 col-12"></div>
        </div>
      </div>
    </>
  );
}
