import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
const Testimonial = () => {
  return (
    <>
      <div className="testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Section Title Start */}
              <div className="section-title">
                <h3 className="wow fadeInUp">Client Testimonials</h3>
                <h2 className="text-anime">What Our Client Say</h2>
              </div>
              {/* Section Title End */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* Testimonial Carousel Start */}
              <div className="testimonial-carousel">
                <div className="swiper">
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={2}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide>
                      <div className="swiper-slide half-width">
                        <div className="testimonial-slide">
                          <div className="testimonial-header">
                            <div className="author-img">
                              <img src="assests/images/author-1.jpg" alt="" />
                            </div>
                            <div className="author-info">
                              <h3>Emma Johnson</h3>
                              <div className="rating-star">
                                <img
                                  src="assests/images/icon-rating.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="testimonial-content">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat. Duis aute irure
                              dolor in reprehenderit in voluptate velit esse
                              cillum dolore eu fugiat nulla pariatur.
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide half-width">
                        <div className="testimonial-slide">
                          <div className="testimonial-header">
                            <div className="author-img">
                              <img src="assests/images/author-2.jpg" alt="" />
                            </div>
                            <div className="author-info">
                              <h3>Olivia Davis</h3>
                              <div className="rating-star">
                                <img
                                  src="assests/images/icon-rating.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="testimonial-content">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat. Duis aute irure
                              dolor in reprehenderit in voluptate velit esse
                              cillum dolore eu fugiat nulla pariatur.
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide half-width">
                        <div className="testimonial-slide">
                          <div className="testimonial-header">
                            <div className="author-img">
                              <img src="assests/images/author-2.jpg" alt="" />
                            </div>
                            <div className="author-info">
                              <h3>Olivia Davis</h3>
                              <div className="rating-star">
                                <img
                                  src="assests/images/icon-rating.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="testimonial-content">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat. Duis aute irure
                              dolor in reprehenderit in voluptate velit esse
                              cillum dolore eu fugiat nulla pariatur.
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="swiper-slide half-width">
                        <div className="testimonial-slide">
                          <div className="testimonial-header">
                            <div className="author-img">
                              <img src="assests/images/author-2.jpg" alt="" />
                            </div>
                            <div className="author-info">
                              <h3>Olivia Davis</h3>
                              <div className="rating-star">
                                <img
                                  src="assests/images/icon-rating.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div className="testimonial-content">
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat. Duis aute irure
                              dolor in reprehenderit in voluptate velit esse
                              cillum dolore eu fugiat nulla pariatur.
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    ...
                  </Swiper>
                  <div className="swiper-wrapper">
                    {/* Testimonial Slide Start */}
                    {/* <div className="swiper-slide half-width">
    <div className="testimonial-slide">
      <div className="testimonial-header">
        <div className="author-img">
          <img src="assests/images/author-1.jpg" alt="" />
        </div>
        <div className="author-info">
          <h3>Emma Johnson</h3>
          <div className="rating-star">
            <img src="assests/images/icon-rating.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="testimonial-content">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu
          fugiat nulla pariatur.
        </p>
      </div>
    </div>
  </div> */}
                    {/* Testimonial Slide End */}
                    {/* Testimonial Slide Start */}

                    {/* Testimonial Slide End */}
                  </div>

                  <div className="swiper-pagination" />
                </div>
              </div>
              {/* Testimonial Carousel End */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
