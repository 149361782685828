import Regerister from "../../Component/Auth/Regerister";
// import Layout from "../../Component/Layout/Layout";

const Regeristerpage = () => {
  return (
    <>
      {/* <Layout> */}
        <Regerister/>
      {/* </Layout> */}
    </>
  );
};

export default Regeristerpage;
