import React from 'react'
import ContactHero from '../../Component/Contact/ContactHero'

export default function Contact() {
  return (
    <div>
      <ContactHero/>
    </div>
  )
}
