/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { selectedServicesNew } from "../../Slice/serviceSlice";
import { useDispatch } from "react-redux";
import { axiosInstance } from "../../utils/axiosInstance";
const Header = () => {
  const router = useLocation();
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("accessToken", accessToken);
    localStorage.removeItem("user", JSON.stringify(user));
    navigate("/login");
  };

  useEffect(() => {
    const Category = async () => {
      try {
        const response = await axios.get(
          "https://studioapi.dev-sh.xyz/api/provider-category"
        );
        setCategories(response?.data?.data);
      } catch (err) {
        console.log(err);
      }
    };
    Category();
  }, []);
  console.log(categories);

  const [searchService, setSearchService] = useState([]);
  const [serviceName, setServiceName] = useState("");

  const handleServiceClick = (service) => {
    try {
      console.log(service, "Selected Service");
      const { provider_category_detail } = service;
      dispatch(selectedServicesNew(service)); // Redux action call
      navigate(`services/${provider_category_detail?.id}`);
    } catch (error) {
      console.error("Error handling service selection", error);
    }
  };

  const fetchSuggestions = async (name) => {
    if (!name) {
      setSearchService([]);
      return;
    }
    try {
      const response = await axiosInstance.get(`search-service/${name}`);
      if (response?.data?.data?.length > 0) {
        setSearchService(response.data.data);
      } else {
        setSearchService([]);
      }
    } catch (error) {
      console.error("Error fetching suggestions", error);
    }
  };
  return (
    <>
      <header className="main-header">
        <div
          className="header-sticky "
          style={
            router.pathname.includes("/providers") ||
            router.pathname.includes("/otherservice")
              ? { backgroundColor: "black" }
              : {}
          }
        >
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              {/* Logo Start */}
              <a className="navbar-brand" href="#">
                <img src="/assests/images/logo.png" alt="Logo" />
              </a>
              {/* Logo End */}
              {/* Main Menu start */}
              <div className="collapse navbar-collapse main-menu">
                <ul className="navbar-nav mr-auto" id="menu">
                  <li className="nav-item">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      About us
                    </a>
                  </li>
                  <li className=" dropdown">
                    <a
                      className="dropdown-toggle"
                      id="servicesDropdown"
                      data-bs-toggle=""
                      aria-expanded="false"
                    >
                      Services
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="servicesDropdown"
                    >
                      {categories.map((c, idx) => (
                        <li key={idx}>
                          <a
                            className="dropdown-item"
                            href={`/providers/${c.id}?cat=${c.name}`}
                            style={{ borderRadius: "12px" }}
                          >
                            {c.name}
                          </a>
                        </li>
                      ))}
                      <li>
                        <a
                          className="dropdown-item"
                          href="/providers"
                          style={{ borderRadius: "12px" }}
                        >
                          All Providers
                        </a>
                      </li>
                      {/*  <li>
                        <a
                          className="dropdown-item"
                          href="/service3"
                          style={{ borderRadius: "12px" }}
                        >
                          Service 3
                        </a>
                      </li> */}
                    </ul>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#">
                      Pricing
                    </a>
                  </li>
                  {/* <li className="nav-item submenu">
                    <a className="nav-link" href="#">
                      Pages
                    </a>
                    <ul>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Service Single
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Blog
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          Blog Single
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          FAQs
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          404
                        </a>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="nav-item">
                    <a className="nav-link" href="#">
                      Contact
                    </a>
                  </li> */}
                  <li className="nav-item highlighted-menu">
                    <a className="nav-link" href="https://saloon.dev-um.xyz">
                      Provider Login
                    </a>
                  </li>
                  <li className="nav-item highlighted-menu">
                    <a className="nav-link" onClickCapture={logout}>
                      {accessToken ? "Log out" : "Login"}
                    </a>
                  </li>
                </ul>
              </div>
              {/* Main Menu End */}
              <div className="navbar-toggle" />
            </div>
            <div className="fadeInUp mt-3">
                 
                  <div className="d-flex  flex-column justify-content-end" style={{paddingRight:'30px'}} >
                <div className="bg-light rounded-pill px-3 py-1 d-flex flex-column gap-2 mb-3 highlighted-menu">
                  <input
                    value={serviceName}
                    onChange={(e) => {
                      setServiceName(e.target.value);
                      fetchSuggestions(e.target.value);
                    }}
                    className="searchinput fw-bold form-control"
                    placeholder="Search"
                  />
                </div>
                {searchService.length > 0 && (
                  <div
                    style={{ position: "absolute", zIndex: 100,bottom:-38,width:"15%" }}
                    className="suggestions-list  mt-1 rounded-pill"
                  >
                    {searchService.map((service, index) => (
                      <div
                      style={{color:'black'}}
                        key={index}
                        className="suggestion-item"
                        onClick={() => handleServiceClick(service)}
                      >
                        
                        {service?.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </nav>
          <div className="responsive-menu" />
        </div>
      </header>
    </>
  );
};

export default Header;
