/* eslint-disable no-unused-vars */
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Loader } from "../Loader/Loader";
import { axiosInstance } from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedServices,
  selectedServicesNew,
} from "../../Slice/serviceSlice";
const OtherServices = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const { Provider } = useSelector((state) => state.auth);

  console.log(Provider, "object");
  localStorage.setItem("global_provider_id", param.id);
  const Navigate = useNavigate();
  const data = useLocation();
  const { image, provider_categories, first_name, provider_category_profiles } =
    data.state;
  console.log(image, "ssssssssssssss");
  //   const [serachParams] = useSearchParams()
  //   console.log(serachParams.get("cat"))
  const [providers, setProviders] = useState(provider_category_profiles);
  const [loader, setLoader] = useState(false);

  console.log(providers, "provider");
  const ServiceAvibility = async (id, data) => {
    console.log(id, data, "dddddddd");
    setLoader(true);
    await axiosInstance
      .get(`category-service?provider_category_detail_id=${id}`)
      .then((res) => {
        if (res.data.data.length > 0) {
          setLoader(false);
          Navigate(`/services/${id}`, { state: data });
        } else {
          toast.error("Service is Not available");
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  //   useEffect(() => {
  //     const fetchProviders = async () => {
  //       setLoader(true);
  //       await axios
  //         .get(
  //           `https://studioapi.dev-sh.xyz/api/provider?category_id=${params.id}`
  //         )
  //         .then((res) => {
  //           setProviders(res.data.data);
  //           setLoader(false);
  //         })
  //         .catch((err) => console.log(err));
  //     };
  //     fetchProviders();
  //   }, [params.id]);

  console.log(providers);
  if (loader) {
    return <Loader />;
  }

  if (providers?.length === 0) {
    return (
      <div className="latest-posts">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Section Title Start */}
              <div className="section-title">
                <h2 className="text-anime">No Providers Category Found</h2>
              </div>
              {/* Section Title End */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="latest-posts">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* Section Title Start */}
            <div className="section-title">
              <h3 className="wow fadeInUp">Other Services</h3>
              <h2 className="text-anime"> {first_name}`s Services</h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        <div className="row">
          {providers?.map((p, idx) => (
            <div
              onClick={() =>
                dispatch(
                  selectedServicesNew({
                    ...Provider,
                    provider_category_detail: p,
                  })
                )
              }
              className="col-lg-4"
              key={idx}
              style={{ marginBottom: "15px" }}
            >
              {/* Post Item Start */}
              <div
                className="post-item wow fadeInUp"
                onClick={() => ServiceAvibility(p?.id, data)}
                data-wow-delay="0.5s"
              >
                {/* Post Featured Image Start */}
                <div className="post-featured-image">
                  <div>
                    <figure className="hover-anime">
                      <img
                        src={image}
                        alt=""
                        height={300}
                        width={500}
                        style={{ objectFit: "cover" }}
                      />
                    </figure>
                  </div>
                </div>
                {/* Post Featured Image End */}
                {/* Post Header Start */}
                <div className="post-header">
                  <h3>{p?.provider_category?.name}</h3>
                  <div className="post-meta">
                    <ul>
                      <li>{p.about}</li>
                    </ul>
                  </div>
                </div>
                {/* Post Header End */}
                {/* Post Read More Button Start */}
                <div className="post-readmore">
                  <img src="/assests/images/icon-readmore.svg" alt="" />
                </div>
                {/* Post Read More Button End */}
              </div>
              {/* Post Item End */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtherServices;
