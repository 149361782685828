/* eslint-disable react-hooks/exhaustive-deps */
import Layout from "../Component/Layout/Layout";

import ServiceHero from "../Component/Service/ServiceHero";
import Pakages from "../Component/Service/Pakages";
import Team from "../Component/Service/Team";
import Review from "../Component/Service/Review";
import AboutTimming from "../Component/Service/AboutTimming";
import Nearby from "../Component/Service/Nearby";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../Component/Loader/Loader";
import RainBackground from "../Component/ThreeJs/RainBackground";
import { axiosInstance } from "../utils/axiosInstance";
import { useSelector } from "react-redux";

const Services = () => {
  const { ServicesNew } = useSelector((state) => state.auth);
  const {  Provider } = useSelector((state) => state.auth);
  const { provider_category_detail } = Provider;
  // const { SelectedService } = Provider?.provider_category_detail;
  console.log(ServicesNew, "servicepage");

  const providerdata = useLocation();
  const { state } = providerdata.state || {};
  console.log(state?.provider_category_profiles, "providerdara");
  const params = useParams();
  const filterCategory = state?.provider_category_profiles.filter(
    (category) => {
      return category?.id === Number(params.id);
    }
  );

  console.log(filterCategory, "filter");
  const [service, setService] = useState([]);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const fetchProviderDetail = async () => {
      setLoader(true);
      await axiosInstance
        .get(`category-service?provider_category_detail_id=${params.id?params.id:provider_category_detail?.id}`)
        .then((res) => {
          console.log(res.data.data, "servicessssssssss");
          setService(res.data.data);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchProviderDetail();
  }, [params.id]);

  if (loader) {
    return <Loader />;
  }

  return (
    <>
      <div className="tt-magic-cursor">
        <RainBackground />
        <Layout>
          <ServiceHero provider={provider_category_detail} service={service} />
          <Pakages provider={filterCategory} service={service} />
          <Team />
          <Review />
          <AboutTimming provider={filterCategory} />
          <Nearby />
          {/* <AboutSection />
          <HomeServices />
          <WhyChoose />
          <FactsSection />
          <PhotoGallery />
          <PricingSection />
          <GiftCard />
          <Testimonial />
          <LatestPost /> */}
        </Layout>
      </div>
    </>
  );
};

export default Services;
