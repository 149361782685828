import AboutSection from "../Component/Home/AboutSection";
import FactsSection from "../Component/Home/FactsSection";
import GiftCard from "../Component/Home/GiftCard";
import HeroSection from "../Component/Home/HeroSection";
import HomeServices from "../Component/Home/HomeServices";
import LatestPost from "../Component/Home/LatestPost";
import Layout from "../Component/Layout/Layout";
import PhotoGallery from "../Component/Home/PhotoGallery";
import PricingSection from "../Component/Home/PricingSection";
import Testimonial from "../Component/Home/Testimonial";
import WhyChoose from "../Component/Home/WhyChoose";
import RainBackground from "../Component/ThreeJs/RainBackground";
import Contact from "./Contact/Contact";

const Home = () => {
  return (
    <>
      <RainBackground />

      <Layout>
        <HeroSection />
        <AboutSection />
        <HomeServices />
        <WhyChoose />
        <FactsSection />
        <PhotoGallery />
        <PricingSection />
        <GiftCard />
        <Testimonial />
        <LatestPost />
        <Contact/>
      </Layout>
    </>
  );
};

export default Home;
