import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Loader } from "../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { selectedServicesNew } from "../../Slice/serviceSlice";
const Providers = () => {
  const data = useSelector((state) => state);
  console.log(data,"Selectors")
  const dispatch = useDispatch();
  const params = useParams();
  const [serachParams] = useSearchParams();
  console.log(serachParams.get("cat"));
  const [providers, setProviders] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchProviders = async () => {
      setLoader(true);
      await axios
        .get(
          `https://studioapi.dev-sh.xyz/api/provider?category_id=${params.id}`
        )
        .then((res) => {
          setProviders(res.data.data);
          setLoader(false);
        })
        .catch((err) => console.log(err));
    };
    fetchProviders();
  }, [params.id]);
  console.log(providers);
  if (loader) {
    return <Loader />;
  }

  if (providers.length === 0) {
    return (
      <div className="latest-posts">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* Section Title Start */}
              <div className="section-title">
                <h2 className="text-anime">No Providers Found</h2>
              </div>
              {/* Section Title End */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="latest-posts">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* Section Title Start */}
            <div className="section-title">
              <h3 className="wow fadeInUp">Providers</h3>
              <h2 className="text-anime">{serachParams.get("cat")}</h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        {console.log(providers, "provider")}
        <div className="row">
          {providers?.map((p, idx) => (
            <div
              className="col-lg-4"
              onClick={() => dispatch(selectedServicesNew(p))}
              key={idx}
              style={{ marginBottom: "15px" }}
            >
              {/* Post Item Start */}
              <div className="post-item wow fadeInUp" data-wow-delay="0.5s">
                {/* Post Featured Image Start */}
                <div className="post-featured-image">
                  <Link to={`/otherservice/${p.id}`} state={p}>
                    <figure className="hover-anime">
                      <img
                        src={p.image}
                        alt=""
                        height={300}
                        width={500}
                        style={{ objectFit: "cover" }}
                      />
                    </figure>
                  </Link>
                </div>
                {/* Post Featured Image End */}
                {/* Post Header Start */}
                <div className="post-header">
                  <h3>
                    <Link to={`/otherservice/${p.id}`} state={p}>{p.first_name}</Link>
                  </h3>
                  <div className="post-meta">
                    <ul>
                      <li>
                        <Link to={`/otherservice/${p.id}`} state={p}>{p.about}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* Post Header End */}
                {/* Post Read More Button Start */}
                <div className="post-readmore">
                  <Link to={`/otherservice/${p.id}`} state={p}>
                    <img src="assests/images/icon-readmore.svg" alt="" />
                  </Link>
                </div>
                {/* Post Read More Button End */}
              </div>
              {/* Post Item End */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Providers;
