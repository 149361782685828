import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import serviceSlice from "../Slice/serviceSlice";

const presistconfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const reducer = combineReducers({
  auth: serviceSlice,
});

const presistedReducer = persistReducer(presistconfig, reducer);

const store = configureStore({
  reducer: presistedReducer,
});

export const presiststore = persistStore(store);

export default store;