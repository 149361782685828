/* eslint-disable jsx-a11y/anchor-is-valid */
const AboutSection = () => {
  return (
    <div>
      <div
        className="about-us-section bg-white z-1 position-relative"
        id="aboutus"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              {/* About Left Image Start */}
              <div className="about-image">
                <div className="row">
                  <div className="col-7">
                    <div className="about-img right-shape" style={{display:"flex" , flexDirection:"column", gap:"20px"}}>
                      <figure className="reveal hover-anime">
                        <img src="assests/images/about-img-1.jpg" alt="" style={{objectFit:"cover"}} />
                      </figure>
                      <figure className="reveal hover-anime">
                        <img src="assests/images/2.jpg" alt="" style={{objectFit:"cover"}} />
                      </figure>
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="about-year-image">
                      <div className="about-year">
                        <p>Since</p>
                        <h4 className="counter">1998</h4>
                      </div>
                      <div className="about-img left-shape">
                        <figure className="reveal hover-anime">
                          <img src="assests/images/about-img-2.jpg" alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* About Left Image End */}
            </div>
            <div className="col-lg-6">
              {/* Section Title Start */}
              <div className="section-title">
                <h3 className="wow fadeInUp">About Glimy</h3>
                <h2 className="text-anime">
                  Luxury Services Where You Will Feel Unique
                </h2>
              </div>
              {/* Section Title End */}
              {/* About Content Start */}
              <div
                className="about-content wow fadeInUp"
                data-wow-delay="0.75s"
              >
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy
                </p>
                <ul>
                  <li>
                    <span>01.</span> The hair cutting and styling with 10 years
                    of experience.
                  </li>
                  <li>
                    <span>02.</span> Update the latest technology and tendency
                    in the world.
                  </li>
                  <li>
                    <span>03.</span> Using the best products from the top
                    providers.
                  </li>
                </ul>
                <a href="#" className="btn-default">
                  Read More
                </a>
              </div>
              {/* About Content End */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
