/* eslint-disable no-unused-vars */
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
// import CustomCursor from "./Component/Cursor";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Loginpage from "./pages/Auth/Loginpage";
import Regeristerpage from "./pages/Auth/Regeristerpage";
import { Forgetpage } from "./pages/Auth/Forgetpage";
import Providers from "./Component/providers/Providers";
import AllServices from "./Component/Service/AllServices";
import ProvidersById from "./pages/ProvidersById";
import AllProviders from "./pages/AllProviders";
import OtherService from "./pages/OtherService";
import BookNow from "./pages/BookNow";
import CustomCursor from "./Component/Cursor";
import Professional from "./pages/Professional";

function App() {
  return (
    <>
      <CustomCursor />
      {/* <Home /> */}

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services/:id" element={<Services />} />
          <Route path="/providers/:id" element={<ProvidersById />} />
          {/* <Route path="/services/:id" element={<Providers/>} /> */}
          <Route path="providers" element={<AllProviders />} />
          <Route path="professionals" element={<Professional />} />

          <Route path="otherservice/:id" element={<OtherService />} />
          <Route path="booknow/:id" element={<BookNow />} />
          <Route path="/login" element={<Loginpage />} />
          <Route path="/Regeristerpage" element={<Regeristerpage />} />
          <Route path="/Forget" element={<Forgetpage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
