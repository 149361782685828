import React from "react";
import Providers from "../Component/providers/Providers";
import Layout from "../Component/Layout/Layout";

const ProvidersById = () => {
  return (
    <Layout>
      <Providers />
    </Layout>
  );
};

export default ProvidersById;
