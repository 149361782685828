import Login from "../../Component/Auth/Login";
// import Layout from "../../Component/Layout/Layout";

const Loginpage = () => {
  return (
    <>
      {/* <Layout> */}
        <Login />
      {/* </Layout> */}
    </>
  );
};

export default Loginpage;
