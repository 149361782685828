/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { axiosInstance } from "../../utils/axiosInstance";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { selectedServicesNew } from "../../Slice/serviceSlice";

const SelectProfessional = ({ selectedId, setIds, ids }) => {
  const dispatch = useDispatch();

  const { Services, Provider } = useSelector((state) => state.auth);
  const { SelectedService } = Provider.provider_category_detail || {};

  const [loader, setLoader] = useState(false);
  const [professional, setProfessional] = useState([]);

  const gtProfessional = async () => {
    setLoader(true);

    try {
      const formData = new FormData();

      if (SelectedService && SelectedService.length) {
        SelectedService.forEach((element, index) => {
          formData.append(`services[${index}]`, element.id);
        });
      }

      const res = await axiosInstance.post(
        "/get-professional-by-service",
        formData
      );

      if (res.status === 200) {
        setProfessional(res?.data?.data);
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred. Please try again.", {
        duration: 5000,
        position: "top-right",
      });
    } finally {
      setLoader(false);
    }
  };

  // Set default `is_any` to 1 for all SelectedServices
  useEffect(() => {
    if (SelectedService?.length) {
      const updatedServices = SelectedService.map((service) => ({
        ...service,
        is_any: service.is_any ?? 1, // Ensure `is_any` defaults to 1 if not set
        selectProfessional: null,
      }));

      dispatch(
        selectedServicesNew({
          ...Provider,
          provider_category_detail: {
            ...Provider.provider_category_detail,
            SelectedService: updatedServices,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    gtProfessional();
  }, []);
  console.log(Provider, "Provider");
  return (
    <>
      {SelectedService?.map((service) => (
        <div className="mt-3" key={service?.id}>
          <h5 className="text-black">{service?.name}</h5>
          <div className="d-flex justify-content-md-start flex-wrap gap-3">
            <div
              className="custom-box_pro"
              onClick={() => {
                dispatch(
                  selectedServicesNew({
                    ...Provider,
                    provider_category_detail: {
                      ...Provider.provider_category_detail,
                      SelectedService: SelectedService.map((selectService) =>
                        selectService.id === service.id
                          ? {
                              ...selectService,
                              is_any: 1,
                              selectProfessional: null,
                            }
                          : selectService
                      ),
                    },
                  })
                );
              }}
              style={{
                border: service.is_any === 1 ? "2px solid blue" : "none",
              }}
            >
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img
                  className="rounded-circle"
                  src="/assests/images/add.png"
                  alt="any"
                />
                <div className="d-flex flex-column mt-4">
                  <h6 className="text-center text-white">Any Professional</h6>
                  <p className="text-center">For Maximum Ability</p>
                </div>
              </div>
            </div>

            {service?.professionals?.map((pro) => (
              <div
                key={pro?.id}
                className="custom-box_profesional"
                style={{
                  border:
                    service.selectProfessional?.id === pro?.id
                      ? "3px solid blue"
                      : "none",
                }}
                onClick={() => {
                  dispatch(
                    selectedServicesNew({
                      ...Provider,
                      provider_category_detail: {
                        ...Provider.provider_category_detail,
                        SelectedService: SelectedService.map((selectService) =>
                          selectService.id === service.id
                            ? {
                                ...selectService,
                                is_any: 0,
                                selectProfessional: pro,
                              }
                            : selectService
                        ),
                      },
                    })
                  );
                }}
              >
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <img
                    className="rounded-circle profile"
                    src={pro?.image}
                    alt="professional"
                  />
                  <button className="custom-button">0.0</button>
                  <h5 className="text-center">{pro?.first_name}</h5>
                  <h6 className="text-center">{pro?.last_name}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default SelectProfessional;
