/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";

import moment from "moment/moment";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateSlots from "../Component/BookNow/DateSlots";
import Layout from "../Component/Layout/Layout";
import SelectedServices from "../Component/Service/selectedServices";
import RainBackground from "../Component/ThreeJs/RainBackground";
import { axiosInstance } from "../utils/axiosInstance";
import { resetServices, selectedServicesNew } from "../Slice/serviceSlice";

const BookNow = () => {
  const params = useParams();
  const Professional_id = params.id;
  // Logging the Services array
  const { Services, Provider } = useSelector((state) => state.auth);
  console.log(Provider, "provider");
  const { SelectedService } = Provider.provider_category_detail;
  const { provider_category_detail } = Provider;

  console.log(SelectedService, "SelectedService");
  console.log(provider_category_detail, "provider_category_detail");
  const [disable, setdisable] = useState(false);
  // Ensure valid price values are summed
  const totalPrice = SelectedService?.reduce((sum, Service) => {
    // Check if the price is a valid number
    const price = parseFloat(Service.price); // Convert to number if it's a string
    return !isNaN(price) ? sum + price : sum; // Add only if it's a valid number
  }, 0);

  const totalMinutes = SelectedService?.reduce((sum, Service) => {
    const timeParts = Service.time.split(":").map(Number); // Split time into parts
    let minutes;
    if (timeParts.length === 2) {
      // If time is in HH:mm format
      const [hours, mins] = timeParts;
      minutes = hours * 60 + mins; // Convert to total minutes
    } else if (timeParts.length === 1) {
      // If time is only minutes
      minutes = timeParts[0];
    } else {
      minutes = 0; // Handle invalid format
    }
    return sum + minutes; // Add to total
  }, 0);

  // Convert total minutes back to HH:mm format
  const totalHours = Math.floor(totalMinutes / 60);
  const remainingMinutes = totalMinutes % 60;

  const totalDuration = `${String(totalHours).padStart(2, "0")}:${String(
    remainingMinutes
  ).padStart(2, "0")}`;

  console.log(totalDuration); // Final duration in HH:mm format

  // const {
  //   address,
  //   first_name,
  //   last_name,
  //   provider_images,
  //   provider_category,
  //   id,
  // } = Services?.[0]?.provider[0];
  const data = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = data;

  const [slot, setSlot] = useState({ day: "", time: "" });
  console.log(slot, SelectedService, "slote");
  const handleSubmit = async () => {
    setdisable(true);
    const formData = new FormData();
    formData.append("provider_id", Provider?.id);

    formData.append(
      "provider_category_id",
      provider_category_detail?.provider_category?.id
    );

    // formData.append("provider_category_id", provider_category_detail?.id);
    formData.append("total_price", totalPrice);
    formData.append("date", moment(slot.day).format("yyyy-MM-DD"));
    formData.append("time", totalDuration);
    formData.append("total_duration", totalDuration);

    SelectedService?.forEach((service, index) => {
      formData.append(`services[${index}][service_id]`, service.id);
      if (service?.selectProfessional === null) {
        formData.append(`services[${index}][professional_any]`, 1);
      } else {
        formData.append(`services[${index}][professional_any]`, 0);
        formData.append(
          `services[${index}][professional_id]`,
          service?.selectProfessional?.id
        );
      }

      formData.append(`services[${index}][service_id]`, service?.id);
      formData.append(`services[${index}][price]`, service.price);
      formData.append(`services[${index}][time]`, service.timeSlot);
      formData.append("professional_any", service?.is_any);
    });

    try {
      const response = await axiosInstance.post("booking", formData);
      console.log(response, "response");
      setdisable(false);
      toast.success(response?.data?.message);
      dispatch(resetServices());

      navigate("/");
    } catch (error) {
      console.log(error);
      setdisable(false);

      // Handle the error if needed
      toast.error("Something Went Wrong. Please try again.");
    }
  };
  return (
    <>
      <RainBackground />
      <Layout isFooter={false}>
        <div className="container p-3 fadeInUp">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="text-white">Select Time</h2>
            <MdOutlineCancel size={40} color="#fff" />
          </div>
          <div className="container" data-wow-delay="0.5s">
            <div className="row mt-3">
              <div className="col-md-8">
                <DateSlots
                  data={state}
                  disable={disable}
                  setdisable={setdisable}
                  slot={slot}
                  setSlot={setSlot}
                />
              </div>
              <div className="col-md-4">
                <SelectedServices disable={disable} onClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BookNow;
