import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";

import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils/axiosInstance";
import RainBackground from "../ThreeJs/RainBackground";
// import { Forget } from './Forget';

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

const Login = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (values, { resetForm }) => {
    setLoader(true);

    axiosInstance
      .post(`login`, values)

      .then((response) => {
        console.log(response);
        const { status, accessToken, user } = response.data.data;

        if (status && accessToken) {
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("user", JSON.stringify(user));
          navigate("/");
          toast.success("Login successful!");
          resetForm();
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        // toast.error(error?.message);

      });
  };
  return (
    <>
      <RainBackground />
      <div className="global-container vh-100">
        <div className="card login-form" style={{ borderRadius: "30px" }}>
          <div className="card-body p-6 px-4 py-4">
            <h3 className="card-title text-center text-white text-3xl mb-4">
              Log in
            </h3>
            <div className="card-text">
              {/* Formik Form */}
              <Formik
                initialValues={{ email: "", password: "", role: "user" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                <Form>
                  {/* Form Group for Email */}
                  <div className="form-group mb-4">
                    <label htmlFor="email" className="text-white text-lg">
                      Email address
                    </label>
                    <Field
                      type="email"
                      name="email"
                      className="form-control form-control-lg"
                      id="email"
                      aria-describedby="emailHelp"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger text-sm mt-1"
                    />
                  </div>

                  {/* Form Group for Password */}
                  <div className="form-group mb-4">
                    <label htmlFor="password" className="text-white text-lg">
                      Password
                    </label>

                    <Field
                      type="password"
                      name="password"
                      className="form-control form-control-lg text-black"
                      id="password"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger text-sm mt-1"
                    />
                    <Link
                      // href=""
                      to={"/Forget"}
                      style={{ float: "right", fontSize: 14 }}
                      className="text-white"
                    >
                      Forgot password?
                    </Link>
                  </div>

                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="btn btn-lg btn-block text-center text-black mt-2"
                    style={{ background: "white", width: "100%" }}
                    // disabled={isSubmitting || loader}
                  >
                    {loader ? "Logging..." : "Log in"}
                  </button>

                  {/* Sign-up Link */}
                  <div className="sign-up mt-2 text-white text-center">
                    Don't have an account?{" "}
                    <Link to="/Regeristerpage" className="underline">
                      Create One
                    </Link>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
