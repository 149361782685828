import React from "react";
import Layout from "../Component/Layout/Layout";
import Professionals from "../Component/Professionals";

const Professional = () => {
  return (
    <Layout>
      <Professionals />
    </Layout>
  );
};

export default Professional;
