/* eslint-disable jsx-a11y/anchor-is-valid */
const Footer = () => {
  return (
    <footer className="footer">
      {/* Footer Contact Information Section Start */}
      <div className="footer-contact-information">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              {/* Footer Contact Info Box Start */}
              <div className="contact-info-item wow fadeInUp">
                <div className="icon-box">
                  <img src="/assests/images/logo.png" alt="" />
                </div>
                <h3>Our Location</h3>
                <p>
                  Glimy, 123 Main Street <br />
                  Citytown, Stateville 12345
                  <br /> United States
                </p>
              </div>
              {/* Footer Contact Info Box End */}
            </div>
            <div className="col-md-4">
              {/* Footer Contact Info Box Start */}
              <div
                className="contact-info-item wow fadeInUp"
                data-wow-delay="0.25s"
              >
                <div className="icon-box">
                  <img src="/assests/images/icon-email-phone.svg" alt="" />
                </div>
                <h3>Get in Touch</h3>
                <p>
                  Phone: +01 123 456 7890 <br />
                  Email: info@domain.com
                  <br /> Fax: 789 456 1234
                </p>
              </div>
              {/* Footer Contact Info Box End */}
            </div>
            <div className="col-md-4">
              {/* Footer Contact Info Box Start */}
              <div
                className="contact-info-item wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="icon-box">
                  <img src="/assests/images/icon-working-hours.svg" alt="" />
                </div>
                <h3>Working Hours</h3>
                <p>
                  Mon-Fri: 10:00 AM - 9:00 PM <br />
                  Saturday: 10:00 AM - 7:00 PM <br />
                  Sunday: 10:00 PM - 7:00 PM
                </p>
              </div>
              {/* Footer Contact Info Box End */}
            </div>
          </div>
        </div>
      </div>
      {/* Footer Contact Information Section End */}
      {/* Main Footer Start */}
      <div className="footer-main">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              {/* Footer Logo Start */}
              <div className="footer-logo">
                <img src="/assests/images/logo.png" alt="" />
              </div>
              {/* Footer Logo End */}
              {/* Footer Social Icons Start */}
              <div className="footer-social">
                <ul>
                  <li>
                    <a href="#">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                </ul>
              </div>
              {/* Footer Social Icons End */}
            </div>
            <div className="col-lg-7">
              {/* Footer Menu Start */}
              <div className="footer-menu">
                <ul>
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a href="about.html">About Us</a>
                  </li>
                  <li>
                    <a href="pricing.html">Pricing</a>
                  </li>
                  <li>
                    <a href="services.html">Services</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                  <li>
                    <a href="blog.html">Blog</a>
                  </li>
                </ul>
              </div>
              {/* Footer Menu End */}
              {/* Footer Copyright Start */}
              <div className="copyright">
                <p>Copyright © 2024 HNH Tech Solution. All Rights Reserved.</p>
              </div>
              {/* Footer Copyright End */}
            </div>
          </div>
        </div>
      </div>
      {/* Main Footer End */}
    </footer>
  );
};

export default Footer